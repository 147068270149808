@tailwind base;
@tailwind components;
@tailwind utilities;


body::-webkit-scrollbar {
  display: none;
}


/* Define transitions */
.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}
